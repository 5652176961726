function handleErrorMessage(obj) {
    let showErrorMessage = "";
    for (const [key] of Object.entries(obj)) {
        switch (key) {
            case "feedback_id":
                showErrorMessage = "客訴單編號錯誤";
                break;
            case "defendant_phone":
                showErrorMessage = "客訴單對象手機錯誤";
                break;
            case "phone":
                showErrorMessage = "手機錯誤";
                break;
            case "order_id":
                showErrorMessage = "訂單編號錯誤";
                break;
            case "url":
                showErrorMessage = "連結錯誤";
                break;
            default:
                showErrorMessage = "";
        }
    }
    return showErrorMessage;
}

export { handleErrorMessage };
